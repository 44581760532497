// Syntax highlighting for code blocks (light)
// Get it from here https://github.com/richleland/pygments-css

.highlight .w   { color: #bbbbbb } /* Text.Whitespace */
.highlight .k   { color: #0000aa } /* Keyword */
.highlight .m   { color: #009999 } /* Literal.Number */
.highlight .s   { color: #aa5500 } /* Literal.String */
.highlight .c   { color: #aaaaaa; font-style: italic } /* Comment */
.highlight .cp  { color: #4c8317 } /* Comment.Preproc */
.highlight .gd  { color: #aa0000 } /* Generic.Deleted */
.highlight .gr  { color: #aa0000 } /* Generic.Error */
.highlight .gi  { color: #00aa00 } /* Generic.Inserted */
.highlight .go  { color: #888888 } /* Generic.Output */
.highlight .gp  { color: #555555 } /* Generic.Prompt */
.highlight .gt  { color: #aa0000 } /* Generic.Traceback */
.highlight .kc  { color: #0000aa } /* Keyword.Constant */
.highlight .kd  { color: #0000aa } /* Keyword.Declaration */
.highlight .kn  { color: #0000aa } /* Keyword.Namespace */
.highlight .kp  { color: #0000aa } /* Keyword.Pseudo */
.highlight .kr  { color: #0000aa } /* Keyword.Reserved */
.highlight .kt  { color: #00aaaa } /* Keyword.Type */
.highlight .na  { color: #1e90ff } /* Name.Attribute */
.highlight .nb  { color: #00aaaa } /* Name.Builtin */
.highlight .nc  { color: #00aa00 } /* Name.Class */
.highlight .no  { color: #aa0000 } /* Name.Constant */
.highlight .nd  { color: #888888 } /* Name.Decorator */
.highlight .nf  { color: #00aa00 } /* Name.Function */
.highlight .nn  { color: #00aaaa } /* Name.Namespace */
.highlight .nv  { color: #aa0000 } /* Name.Variable */
.highlight .ow  { color: #0000aa } /* Operator.Word */
.highlight .mb  { color: #009999 } /* Literal.Number.Bin */
.highlight .mf  { color: #009999 } /* Literal.Number.Float */
.highlight .mh  { color: #009999 } /* Literal.Number.Hex */
.highlight .mi  { color: #009999 } /* Literal.Number.Integer */
.highlight .mo  { color: #009999 } /* Literal.Number.Oct */
.highlight .sa  { color: #aa5500 } /* Literal.String.Affix */
.highlight .sb  { color: #aa5500 } /* Literal.String.Backtick */
.highlight .sc  { color: #aa5500 } /* Literal.String.Char */
.highlight .dl  { color: #aa5500 } /* Literal.String.Delimiter */
.highlight .sd  { color: #aa5500 } /* Literal.String.Doc */
.highlight .s2  { color: #aa5500 } /* Literal.String.Double */
.highlight .se  { color: #aa5500 } /* Literal.String.Escape */
.highlight .sh  { color: #aa5500 } /* Literal.String.Heredoc */
.highlight .si  { color: #aa5500 } /* Literal.String.Interpol */
.highlight .sx  { color: #aa5500 } /* Literal.String.Other */
.highlight .sr  { color: #009999 } /* Literal.String.Regex */
.highlight .s1  { color: #aa5500 } /* Literal.String.Single */
.highlight .ss  { color: #0000aa } /* Literal.String.Symbol */
.highlight .bp  { color: #00aaaa } /* Name.Builtin.Pseudo */
.highlight .fm  { color: #00aa00 } /* Name.Function.Magic */
.highlight .vc  { color: #aa0000 } /* Name.Variable.Class */
.highlight .vg  { color: #aa0000 } /* Name.Variable.Global */
.highlight .vi  { color: #aa0000 } /* Name.Variable.Instance */
.highlight .vm  { color: #aa0000 } /* Name.Variable.Magic */
.highlight .il  { color: #009999 } /* Literal.Number.Integer.Long */
.highlight .ge  { font-style: italic } /* Generic.Emph */
.highlight .gs  { font-weight: bold }  /* Generic.Strong */
.highlight .c1  { color: #aaaaaa; font-style: italic } /* Comment.Single */
.highlight .cs  { color: #0000aa; font-style: italic } /* Comment.Special */
.highlight .ch  { color: #aaaaaa; font-style: italic } /* Comment.Hashbang */
.highlight .cm  { color: #aaaaaa; font-style: italic } /* Comment.Multiline */
.highlight .gh  { color: #000080; font-weight: bold }  /* Generic.Heading */
.highlight .gu  { color: #800080; font-weight: bold }  /* Generic.Subheading */
.highlight .ni  { color: #880000; font-weight: bold }  /* Name.Entity */
.highlight .nt  { color: #1e90ff; font-weight: bold }  /* Name.Tag */
.highlight .err { color: #FF0000 } /* Error */
.highlight .cpf { color: #aaaaaa; font-style: italic } /* Comment.PreprocFile */