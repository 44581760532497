.social {
  text-decoration: none !important;
}

#attribution {
  opacity: 0.5;
  a {
    text-decoration: none;
    font-weight: bold;
  }
}

@media only screen and (min-width: 768px) {
  #attribution {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    position: fixed;
    bottom: 120px;
    right: 22px;
  }
}

.github-footer {
  font-size: 10px;
  color: #6c757d;
  text-decoration: underline;
  text-decoration-color: yellow;
  text-align: right;
  margin-top: 4em;
  margin-right: 2em;
}
