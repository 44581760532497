$dark-100: $dark;
$dark-300: #2c3032;
$dark-600: #242526;
$dark-900: #17191a;

[data-theme="dark"] {
  body {
    background-color: $dark-900;
    color: $text-white;
  }

  // Code blocks & Syntax highlighting

  @import "native.scss";

  pre,
  code.highlighter-rouge {
    @extend .highlight;
    border: none;
  }

  .gist {
    filter: invert(90%) hue-rotate(180deg);
  }

  // Inputs
  input {
    background-color: $dark-300;
    border-color: $dark-100;
    color: $dark-900;

    &::placeholder {
      color: rgba($text-white, 0.64);
    }

    &:focus {
      background-color: $dark-600;
      color: $text-white;
    }
  }

  // Cards
  .card {
    background-color: $dark-600;
  }

  .card.border {
    .card-footer {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  // Tables
  table:not(.highlight) {
    td {
      border-color: $dark-600;
    }

    tr:nth-child(even) {
      background-color: $dark;
    }
  }

  // Lists
  .list-group-item-action {
    background-color: $dark-600;
    color: $text-white;

    &:hover,
    &:focus {
      background-color: darken($color: $dark-600, $amount: 3);
    }
  }

  // Post
  .post footer {
    text-decoration: none;
  }

  // Timeline 
  .timeline-body .timeline-item:after{
    background-color: $dark-900;
  }
}
