$primary:   #007bff;
$secondary: #6c757d;
$success:   #28a745;
$info:      #17a2b8;
$warning:   #ffc107;
$danger:    #dc3545;
$light:     #f8f9fa;
$dark:      #343a40;

$text-black: #343a40;
$text-white: rgba(white, .9);

$rounded-borders: 15px;