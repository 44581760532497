.search-box {
  box-shadow: none !important;
}

.post.card {
  border-radius: $rounded-borders;
  text-decoration: none !important;
  padding: 15px;
  height: 100%;

  .card-footer {
    font-size: 14px;
    margin: 0 -15px -15px -15px;
    padding: 15px 35px;
  }
}

.post-metadata {
  font-size: 14px;
  margin-top: -6px;

  .tag:hover {
    background-color: $primary;
    color: white !important;
  }
}
