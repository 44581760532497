@mixin themed($property, $light-value, $dark-value) {
  [data-theme="light"] & {
    #{$property}: $light-value;
  }

  [data-theme="dark"] & {
    #{$property}: $dark-value;
  }
}

#theme-toggler:before {
  @include themed(content, "🌙", "🌞");
}

.navbar-themed {
  .navbar-brand,
  .navbar-nav .nav-link.active {
    @include themed(color, $text-black, $text-white);
  }

  .navbar-nav .nav-link {
    @include themed(color, rgba($text-black, 0.5), rgba($text-white, 0.5));
  }
}

.text-themed {
  @include themed(color, $text-black, $text-white);
}

.bg-themed {
  @include themed(background-color, $light, $dark);
}

