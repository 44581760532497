@charset "utf-8";

// Custom Fonts
@import url('https://fonts.googleapis.com/css?family=Poppins');

// Import partials from `sass_dir` (defaults to `_sass`)
@import "variables",
        "404",
        "base",
        "blog",
        "footer",
        "landing",
        "navbar",
        "markdown",
        "projects",
        "timeline",
        "autumn",
        "theme",
        "theme-dark";